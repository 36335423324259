import styled from 'styled-components'

const Button = styled.button`
  display: inline-block;
  padding: 0 1rem;
  line-height: 2rem;
  font-weight: 100;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.9s border-color;
  color: var(--colorPrimary);
  border: 3px solid var(--colorPrimary);
  background: color(var(--colorTertiary) a(0.2));

  &:hover {
    text-decoration: none;
    color: var(--colorPrimaryDark);
    border-color: var(--colorPrimaryDark);
  }

  &.big {
    font-size: 1.5rem;
    padding: 0 2rem;
    line-height: 3rem;
  }

  &.small {
    font-size: 0.75rem;
    padding: 0 1rem;
    line-height: 1.5rem;
  }
`

// const Button = ({className, secondary, light, big, small, ...otherProps}) => (
//   <button
//     {...otherProps}
//     className={[
//       className,
//       styles.button,
//       (light ? styles.light : null),
//       (big ? styles.big : null),
//       (small ? styles.small : null),
//     ].join(' ')}
//   />
// )

// Button.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   onClick: PropTypes.func,
//   secondary: PropTypes.bool,
//   light: PropTypes.bool,
//   big: PropTypes.bool,
//   small: PropTypes.bool,
// }

// Button.displayName = 'Button'

export default Button
