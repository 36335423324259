import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Cookies from 'js-cookies'
import Button from '../Button'
import Toast from '../Toast'
import styled from 'styled-components';

const ActionWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-content: center;
  align-items: stretch;
`;

const InvertedButton = styled(Button)`
  color: var(--colorSecondary);
  border: 3px solid var(--colorSecondary);
  background: var(--colorSecondaryDark);
  &:hover {
    color: var(--colorSecondary);
    border-color: var(--colorSecondary);
  }
`

const OPTIN_COOKIE_KEY = '__deofhet_cookie_optin'

// initialise data layer
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || []
}

export default function CookieBar() {
  const [showMessage, setShowMessage] = useState(false)
  const [hasOptin, setHasOptin] = useState(false)

  useEffect(() => {
    // chec if cookie is set on mount
    const OPTIN_COOKIE_VALUE = Cookies.getItem(OPTIN_COOKIE_KEY) || false
    setHasOptin(OPTIN_COOKIE_VALUE)
  }, [])

  useEffect(() => {
    if (hasOptin === false) {
      setShowMessage(true)
    }
  }, [hasOptin])

  const handleClick = e => {
    Cookies.setItem(OPTIN_COOKIE_KEY, true)

    window.dataLayer.push({
      event: 'GAEvent',
      eventCategory: 'Cookie OptIn',
      eventAction: 'click',
      eventLabel: e.target.value,
    })
    setShowMessage(false)
    setHasOptin(true)
  }

  if (hasOptin) {
    // do not render cookie bar if user has optin
    return null
  }

  return (
    <>
      {hasOptin === false && (
        <Toast visible={showMessage}>
          <p>
            deofhet.nl gebruikt cookies om de nodige sitefunctionaliteit te
            bieden en je ervaring te verbeteren. Door onze website te gebruiken,
            ga je akkoord met onze <Link to="/privacy">privacybeleid</Link> en
            onze <Link to="/cookies">cookie-beleid</Link>.
          </p>
          <ActionWrapper>
            <InvertedButton onClick={handleClick} value="yes">
              Ok
            </InvertedButton>
          </ActionWrapper>
        </Toast>
      )}
    </>
  )
}
