import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

export function Seo({
    lang,
    title,
    image,
    description,
  }) {
  const {pathname} = useLocation()
  const {site} = useStaticQuery(seoQuery)

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    author,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    imgage: image || defaultImage,
    url: `${siteUrl}${pathname}`,
  }
  return <Helmet htmlAttributes={{lang}} title={seo.title} titleTemplate={`%s`}>
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary" />
      {author && <meta name="twitter:creator" content={author} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      {seo.url && <link rel="canonical" href={seo.url} />}
    </Helmet>
}

Seo.defaultProps = {
  lang: `nl`,
  title: null,
  description: null,
  image: null,
  meta: [],
}

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
}

const seoQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        author
      }
    }
  }
`
