import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  padding: 1.5rem 0;
  border-top: 3px solid var(--colorTertiary);
  font-size: 0.9rem;
  line-height: 1rem;
  color: var(--colorTertiaryDark);
  text-decoration: none;
`
const Footer = ({children}) => (
  <Wrapper>
    <p>{children}</p>
  </Wrapper>
)

export default Footer
