import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import {Logo} from '../Logo'

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  border-bottom: 3px solid var(--colorTertiary);
  border-top: 12px solid var(--colorPrimary);
`

const Container = styled.div`
  margin: 0 auto;
  maxwidth: 960;
  text-align: center;
  padding: 1.45rem 1.0875rem 1rem;
`

const Header = ({siteTitle}) => (
  <Wrapper>
    <Container>
      <Link to="/" title={siteTitle}>
        <Logo />
      </Link>
    </Container>
  </Wrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
