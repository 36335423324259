import styled from 'styled-components'

const Toast = styled.div`
  position: fixed;
  background: var(--colorTertiaryDark);
  color: #fff;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  transform: ${props =>
    props.visible ? 'translate3d(0, 0, 0)' : 'translate3d(0, 300px, 0)'};
  transition: transform 0.3s ease-in-out 0.3s;
`

export default Toast
